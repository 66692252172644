@media (max-width: 320px) {
    .event-card img {
      width: 85%;
      height: auto;
      max-width: 280px; /* Ensure image doesn't overflow */
    }
    .register_cta{
        width: 85%;
    }
  }

  @media (max-width: 480px) {
    .responsive-desc {
      width: 150px;
      height: 2.4em; /* Height for 2 lines */
      WebkitLineClamp: 2;
    }
  }